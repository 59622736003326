import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Typography, Input, Button, Form, message, Spin, Alert } from 'antd';
import { setLocale } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';

import { updateForm } from '../../reducers/formSlice';
import * as ToolUser from '../../tools/ToolUser';
import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const { Title } = Typography

const formLayout = {
  labelCol: {
    md: { span: 6 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 6, span: 16 },
  },
};

const formFullLayout = {
  wrapperCol: {
    md: { span: 24 },
  },
};

export default function Page1Screen (props) {
  const {signupFormId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await ToolRequest.request('GET', `/v2/admin/signup-form/${signupFormId}/name`);
        setFormData(res);
        setLoaded(true);
      } catch (err) {
        message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
      }
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('POST', `/v2/admin/auth/signup-form/login`, {
        token: values.token,
        signupFormId,
      });

      const formRes = await ToolRequest.request('GET', `/v2/admin/signup-form/${signupFormId}`, {
        scope: ['user'],
      });

      const locale = formRes.user?.language || 'zh-tw';

      dispatch(updateForm({
        ...formRes,
        locale,
      }));

      ToolUser.updateLocale(locale);
      dispatch(setLocale(locale));

      message.success(I18n.t('page1ScreenSuccessMessage'));

      if (formRes.status === 'DRAFT') {
        history.replace(`/${signupFormId}/page/2`);
      } else {
        history.replace(`/${signupFormId}/page/4`);
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data.errorCode) {
          case 'INVALID_USER': {
            return message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
          }
          case 'INVALID_TOKEN': {
            return message.error(I18n.t('page1ScreenErrorMessageInvalidToken'));
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'));
        }
      }
      message.error(I18n.t('errorMessageUnknownError'));
    }
  }

  return loaded ? (
    <>
      <Title level={5} id="subheader">
        {I18n.t(formData.isRenewal ? 'page1ScreenHeaderSubRenewal' : 'page1ScreenHeaderSub')}
      </Title>
      <div id="mainContentContainer">
        <div>
          <Spin spinning={loading}>
            <Form
              {...formLayout}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                {...formFullLayout}
              >
                <Title level={5}>{I18n.t('page1ScreenTitle')}</Title>
              </Form.Item>

              <Form.Item
                {...formFullLayout}
              >
                <Alert
                  type="info"
                  message={I18n.t('page1ScreenFormAlertTitle')}
                  description={(
                    <div>{I18n.t('page1ScreenFormAlertContentPre')} <b>{formData.nickname}</b> {I18n.t('page1ScreenFormAlertContentPost')}</div>
                  )}
                  showIcon
                />
              </Form.Item>

              <Form.Item
                label={I18n.t('page1ScreenFormTokenLabel')}
                name="token"
                required
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.length === 4 && value.match(/^\d{4}$/)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(I18n.t('page1ScreenFormTokenLengthErrorMessage')));
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="XXXX"
                  maxLength={4}
                />
              </Form.Item>

              <Form.Item {...formTailLayout}>
                <Button type="primary" htmlType="submit" block>
                  {I18n.t('page1ScreenButtonSubmitLabel')}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  ) : <SpinFull />;
};
