import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { Modal, Button, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import help1 from '../../images/octopus-help-1.jpg';
import help2 from '../../images/octopus-help-2.jpg';

export default function Page3ScreenOctopusHelpModalButton (props) {
  const [modal, setModal] = useState({
    key: Date.now().toString(),
    visible: false,
  });

  return (
    <>
      <Button
        type="link"
        icon={<QuestionCircleOutlined />}
        onClick={() => setModal({
          ...modal,
          key: Date.now().toString(),
          visible: true,
        })}
      />

      <Modal
        key={modal.key}
        title={I18n.t('page3ScreenOctopusHelpModalTitle')}
        footer={null}
        visible={modal.visible}
        onCancel={() => setModal({
          ...modal,
          visible: false,
        })}
      >
        <div style={{marginBottom: 8}}>{I18n.t('page3ScreenOctopusHelpModalText')}</div>
        <Space wrap>
          <img src={help1} />
          <img src={help2} />
        </Space>
      </Modal>
    </>
  );
};
