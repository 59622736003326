import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { Alert, Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Page3ScreenAutosavingMessage (props) {
  const {status, onReload} = props;

  return (
    <div id="autosavingContainer">
      <Alert
        message={{
          'SAVED': I18n.t('page3ScreenAutosavingMessageSaved'),
          'UNSAVED': I18n.t('page3ScreenAutosavingMessageUnsaved'),
          'SAVING': I18n.t('page3ScreenAutosavingMessageSaving'),
        }[status]}
        type={{
          'SAVED': 'success',
          'UNSAVED': 'warning',
          'SAVING': 'warning',
        }[status]}
        icon={{
          'SAVED': null,
          'UNSAVED': null,
          'SAVING': <Spin size="small" indicator={<LoadingOutlined spin />} />,
        }[status]}
        banner
        action={status === 'UNSAVED' ? (
          <Button
            size="small"
            type="primary"
            onClick={() => onReload()}
          >
            {I18n.t('page3ScreenAutosavingMessageSaveButtonLabel')}
          </Button>
        ) : null}
      />
    </div>
  );
};
