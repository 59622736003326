import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Modal, Upload, Button } from 'antd';
import { UploadOutlined, PlusOutlined, FullscreenOutlined } from '@ant-design/icons';

import * as ToolRequest from '../tools/ToolRequest';
import * as ToolDevice from '../tools/ToolDevice';

const config = window.getConfig();

export default function UploadFile (props) {
  const {signupFormId} = useParams();
  const {
    id,
    value = [],
    onChange = () => {},
    maxCount,
  } = props

  const formatUrl = (id) => {
    return `${config.apiHost}/v2/admin/signup-form/${signupFormId}/image/${id}`;
  }

  const [fileList, setFileList] = useState((value || []).map(file => ({
    id: file.id,
    url: formatUrl(file.id),
    thumbUrl: `${formatUrl(file.id)}?thumbnail=true`,
  })));

  const [preview, setPreview] = useState({
    visible: false,
    title: '',
    url: '',
    type: '',
  });

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.url) {
      try {
        const contentType = await ToolRequest.requestGetContentType(file.url);
        file.type = contentType;
      } catch (err) {}
    }

    setPreview({
      url: file.url || file.preview,
      visible: true,
      title: file.name || I18n.t('componentUploadFilePreviewTitle'),
      type: file.type,
    });
  };

  const isMobile = ToolDevice.isMobile();

  return (
    <span id={id}>
      <Upload
        multiple={!maxCount || maxCount > 1}
        action={`${config.apiHost}/v2/admin/signup-form/${signupFormId}/image`}
        name="file"
        accept="image/jpeg,image/png,application/pdf"
        listType="picture-card"
        fileList={fileList}
        withCredentials={true}
        onPreview={handlePreview}
        onChange={(info) => {
          if (info.file.status === 'done') {
            const valueNew = info.fileList.map(fileItem => fileItem.uid !== info.file.uid ? fileItem : {
              ...info.file,
              id: info.file.response.id,
            });
            onChange(valueNew);
            setFileList(valueNew.map(file => ({
              ...file,
              id: file.id,
              url: formatUrl(file.id),
              thumbUrl: `${formatUrl(file.id)}?thumbnail=true`,
            })));
          } else {
            setFileList(info.fileList);
          }
        }}
        onRemove={(file) => {
          return new Promise((resolve) => {
            Modal.confirm({
              title: I18n.t('componentUploadFileRemoveConfirmMessage'),
              okType: 'danger',
              onOk: () => {
                resolve(true);
                setFileList(fileList => {
                  const valueNew = fileList.filter(fileItem => fileItem.id !== file.id);
                  onChange(valueNew);
                  return valueNew.map(file => ({
                    ...file,
                    id: file.id,
                    url: formatUrl(file.id),
                  }));
                });
              },
              onCancel:() => {
                resolve(false);
              }
            });
          });
        }}
      >
        {(!maxCount || fileList.length < maxCount) && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              {I18n.t('componentUploadFileButtonLabel')}
              {maxCount > 1 && (<div>({fileList.length} / {maxCount})</div>)}
            </div>
          </div>
        )}
      </Upload>
      <Modal
        width={isMobile ? '' : '80%'}
        bodyStyle={{ maxHeight: 540, textAlign: 'center' }}
        visible={preview.visible}
        title={preview.title}
        footer={null}
        onCancel={() => setPreview({
          ...preview,
          visible: false,
        })}
      >
        {preview.type === 'application/pdf' && (
          <>
            {!isMobile && (
              <iframe
                style={{ width: '100%', height: 454 }}
                src={preview.url}
              />
            )}
            <Button
              block
              type="primary"
              href={preview.url}
              target="_blank"
              icon={<FullscreenOutlined />}
            >
              {I18n.t('componentUploadFileOpenFileButtonLabel')}
            </Button>
          </>
        )}
        {preview.type.split('/')[0] === 'image' && (
          <img
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            src={preview.url}
          />
        )}
      </Modal>
    </span>
  )
};
