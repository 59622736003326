import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { setLocale } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';

import { Spin } from 'antd';

import AppScreen from '../App/AppScreen';

import { updateForm } from '../../reducers/formSlice';
import * as ToolUser from '../../tools/ToolUser';
import * as ToolRequest from '../../tools/ToolRequest';

import '../../less/SplashScreen.less';

import enUS from 'antd/es/locale/en_US';
import zhTW from 'antd/es/locale/zh_TW';
import zhCN from 'antd/es/locale/zh_CN';

export default function SplashScreen(props) {
  const {signupFormId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const formData = useSelector(state => state.form.value);

  const [loaded, setLoaded] = useState(false);

  let locale = enUS;
  if (formData) {
    switch (formData.locale) {
      case 'zh-tw':
        locale = zhTW;
        break;
      case 'zh-cn':
        locale = zhCN;
        break;
    }
  }

  useEffect(() => {
    dispatch(setLocale('zh-tw'));

    (async () => {
      try {
        const formRes = await ToolRequest.request('GET', `/v2/admin/signup-form/${signupFormId}`, {
          scope: ['user'],
        });

        const locale = formRes.user?.language || 'zh-tw';

        dispatch(updateForm({
          ...formRes,
          locale,
        }));

        ToolUser.updateLocale(locale);
        dispatch(setLocale(locale));

        if (formRes.status === 'DRAFT') {
          history.replace(`/${signupFormId}/page/2`);
        } else {
          history.replace(`/${signupFormId}/page/4`);
        }
        setLoaded(true);
      } catch (err) {
        if (err?.response?.status === 403) {
          history.replace(`/${signupFormId}/page/1`);
          setLoaded(true);
        } else {
          if (err?.response?.data) {
            switch (err.response.data.errorCode) {
              case 'INVALID_USER': {
                return message.error(I18n.t('page1ScreenErrorMessageInvalidUser'));
              }
              case 'INVALID_TOKEN':
              case 'INVALID_PERMISSION': {
                history.replace(`/${signupFormId}/page/1`);
                setLoaded(true);
                return;
              }
              default:
                return message.error(I18n.t('errorMessageUnknownError'));
            }
          }
          message.error(I18n.t('errorMessageUnknownError'));
        }
      }
    })();
  }, []);

  return (
    <ConfigProvider locale={locale}>
      {loaded ? (
        <AppScreen />
      ) : (
        <div id="splashContainer">
          <Spin />
        </div>
      )}
    </ConfigProvider>
  );
};
