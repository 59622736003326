import React, { useState } from 'react';
import { Layout, Breadcrumb, Typography, PageHeader, Button, Space, Dropdown, Menu, message } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { Route, Switch, Link, Redirect, useParams } from 'react-router-dom';
import { I18n, setLocale } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';

import Page1Screen from '../Page1/Page1Screen';
import Page2Screen from '../Page2/Page2Screen';
import Page3Screen from '../Page3/Page3Screen';
import Page4Screen from '../Page4/Page4Screen';
import SplashScreen from '../Splash/SplashScreen';

import logo from '../../images/logo-food-angel.png';

import { updateForm } from '../../reducers/formSlice';
import * as ToolUser from '../../tools/ToolUser';
import * as ToolRequest from '../../tools/ToolRequest';

const { Content, Header, Footer } = Layout;
const { Title, Text } = Typography;

export default function AppScreen(props) {
  const {signupFormId} = useParams();
  const dispatch = useDispatch();

  const formData = useSelector(state => state.form.value);

  return (
    <Layout>
      <Header>
        <div>
          <div id="headerRightContainer">
            <Dropdown overlay={
              <Menu
                items={[
                  {
                    label: '繁',
                    key: 'zh-tw',
                  },
                  {
                    label: '简',
                    key: 'zh-cn',
                  },
                  {
                    label: 'En',
                    key: 'en',
                  },
                ]}
                onClick={(item) => {
                  ToolUser.updateLocale(item.key);
                  dispatch(setLocale(item.key));

                  dispatch(updateForm({
                    locale: item.key,
                  }));

                  (async () => {
                    try {
                      await ToolRequest.request('PUT', `/v2/admin/user/${formData.user.id}`, {
                        language: item.key,
                      });
                    } catch (err) {
                      message.error(I18n.t('errorMessageUnknownError'));
                    }
                  })();
                }}
              />
            }>
              <Button type="default" shape="circle" icon={<GlobalOutlined />} />
            </Dropdown>
          </div>

          <img src={logo} />
          <div>
            <div>{I18n.t('appHeaderTitleMain')}</div>
            <div>{I18n.t('appHeadertitleSub')}</div>
          </div>
        </div>
      </Header>

      <Content id="mainContainer">
        <Switch>
          <Route exact path="/:signupFormId/page/1" component={Page1Screen} />
          <Route exact path="/:signupFormId/page/2" component={Page2Screen} />
          <Route exact path="/:signupFormId/page/3" component={Page3Screen} />
          <Route exact path="/:signupFormId/page/4" component={Page4Screen} />
          <Route path="/:signupFormId">
            <Redirect to={`/${signupFormId}/page/1`} />
          </Route>
        </Switch>
      </Content>

      <Footer>
        {I18n.t('appFooterMessagePre')}
        <a style={{ paddingLeft: 4, paddingRight: 4 }} target="_blank" href="https://wa.me/85293875784">93875784</a>
        {I18n.t('appFooterMessageOr')}
        <a style={{ paddingLeft: 4, paddingRight: 4 }} target="_blank" href="https://wa.me/85261112449">61112449</a>
        {I18n.t('appFooterMessagePost')}
      </Footer>
    </Layout>
  );
}
