import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { Space, Button, Checkbox, message } from 'antd';
import { ReadOutlined, CheckOutlined } from '@ant-design/icons';

export default function ForceOpeningCheckbox (props) {
  const { value, onChange, url, buttonLabel, checkboxLabel, errorMessage } = props;

  const [opened, setOpened] = useState(!!value);

  return (
    <>
      <Space direction="vertical" style={{width: '100%'}}>
        <Button
          type={!opened && !value ? 'danger' : 'primary'}
          icon={opened ? <CheckOutlined /> : <ReadOutlined />}
          href={url}
          target="_blank"
          onClick={() => {
            setOpened(true);
          }}
        >
          {buttonLabel}
        </Button>
        <Checkbox
          checked={value}
          onChange={e => {
            if (e.target.checked && !opened) {
              message.error(errorMessage);
            } else {
              onChange(e.target.checked);
            }
          }}
        >
          {checkboxLabel}
        </Checkbox>
      </Space>
    </>
  );
};
