import React, { useState, useEffect } from 'react';
import { Input, Space, Alert, Checkbox, Spin } from 'antd';
import { I18n } from 'react-redux-i18n';
import { LoadingOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import * as hkid from 'hkid';

export default function HKIDInput (props) {
  const { value = {}, onChange, id } = props;

  const checkValid = (value) => {
    if (value.card && value.card.length === 7 && value.checkDigit) {
      const isValid = hkid.validate(value.card + '' + value.checkDigit, { checkPrefix: true });
      return {
        ...value,
        valid: isValid,
      };
    } else {
      return {
        ...value,
        valid: false,
      };
    }
  };

  let indication = value.valid ? <CheckCircleFilled className="ant-form-item-feedback-icon-success" /> : <CloseCircleFilled className="ant-form-item-feedback-icon-error" />;

  useEffect(() => {
    if (value.card || value.checkDigit) {
      onChange(checkValid(value));
    }
  }, []);

  return (
    <span id={id}>
      <Input
        style={{width: 140}}
        value={value.card}
        maxLength={7}
        onChange={e => {
          onChange(checkValid({
            ...value,
            card: e.target.value,
          }));
        }}
        placeholder="XXXXXXX"
      />
      <span style={{marginLeft: 4, marginRight: 4}}>(</span>
      <Input
        style={{width: 50}}
        value={value.checkDigit}
        maxLength={1}
        onChange={e => {
          onChange(checkValid({
            ...value,
            checkDigit: e.target.value,
          }));
        }}
        placeholder="X"
      />
      <span style={{marginLeft: 4, marginRight: 4}}>)</span>

      <span style={{paddingTop: 2}}>
        {indication}
      </span>
    </span>
  );
};
