import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { Button, Typography, Result } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import SpinFull from '../../components/SpinFull';

const { Title } = Typography;

export default function Page4Screen (props) {
  const {signupFormId} = useParams();
  const history = useHistory();

  const formData = useSelector(state => state.form.value);

  const status = {
    'SUBMITTED': 'success',
    'REJECTED': 'info',
    'EXPIRED': 'warning',
    'SELECTED_HOT_MEAL': 'info',
    'SELECTED_EFOOD_BANK': 'info',
    'APPROVED': 'success',
    'ACCEPTED': 'success',
    'NOT_APPROVED': 'info',
  }[formData.status];

  const title = {
    'SUBMITTED': I18n.t('page4ScreenResultTitleSubmitted'),
    'REJECTED': I18n.t('page4ScreenResultTitleRejected'),
    'EXPIRED': I18n.t('page4ScreenResultTitleExpired'),
    'SELECTED_HOT_MEAL': I18n.t('page4ScreenResultTitleSelectedHotMeal'),
    'SELECTED_EFOOD_BANK': I18n.t('page4ScreenResultTitleSelectedEfoodBank'),
    'APPROVED': I18n.t('page4ScreenResultTitleApproved'),
    'ACCEPTED': I18n.t('page4ScreenResultTitleAccepted'),
    'NOT_APPROVED': I18n.t('page4ScreenResultTitleNotApproved'),
  }[formData.status];

  const subTitle = {
    'SUBMITTED': I18n.t('page4ScreenResultSubTitleSubmitted'),
    'REJECTED': I18n.t('page4ScreenResultSubTitleRejected'),
    'EXPIRED': I18n.t('page4ScreenResultSubTitleExpired'),
    'SELECTED_HOT_MEAL': I18n.t('page4ScreenResultSubTitleSelectedHotMeal'),
    'SELECTED_EFOOD_BANK': I18n.t('page4ScreenResultSubTitleSelectedEfoodBank'),
    'APPROVED': I18n.t('page4ScreenResultSubTitleApproved'),
    'ACCEPTED': I18n.t('page4ScreenResultSubTitleAccepted'),
    'NOT_APPROVED': I18n.t('page4ScreenResultSubTitleNotApproved'),
  }[formData.status];

  return (
    <>
      <Title level={5} id="subheader">
        {I18n.t('page1ScreenHeaderSub')}
      </Title>
      <div id="mainContentContainer">
        <div>
          <Result
            status={status}
            title={title}
            subTitle={subTitle}
            className={['REJECTED', 'EXPIRED', 'SELECTED_HOT_MEAL', 'SELECTED_EFOOD_BANK', 'NOT_APPROVED'].indexOf(formData.status) > -1 ? 'error' : null}
            extra={formData.status === 'EXPIRED' && [
              <Button type="default" key="link1" target="_blank" href="https://jcfoodprog.hk/" icon={<LinkOutlined />}>
                {I18n.t('page4ScreenResultExpiredExtraButton')}
              </Button>,
            ]}
          />
        </div>
      </div>
    </>
  );
};
