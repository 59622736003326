import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    value: {
      locale: 'zh-tw',
      members: [],
    },
  },
  reducers: {
    updateForm: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
})

export const { updateForm } = formSlice.actions;

export default formSlice.reducer;
