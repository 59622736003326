import React, { useState, useEffect } from 'react';
import { Input, Space, Alert, Checkbox, Spin } from 'antd';
import { I18n } from 'react-redux-i18n';
import { LoadingOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import * as ToolRequest from '../tools/ToolRequest';

export default function OctopusInput (props) {
  const { value = {}, onChange, id } = props;

  const [loading, setLoading] = useState(false);

  const checkValid = (value) => {
    if (value.card && value.card.length >= 8 && (value.checkDigit || value.noCheckDigitChecked)) {

      setLoading(true);

      (async () => {
        try {
          const res = await ToolRequest.request('POST', `/v2/admin/signup-form/verify-octopus`, {
            card: value.card,
            checkDigit: value.checkDigit,
            noCheckDigitChecked: value.noCheckDigitChecked,
          });

          onChange({
            ...value,
            valid: true,
          });
          setLoading(false);
        } catch (err) {
          onChange({
            ...value,
            valid: false,
          });
          setLoading(false);
        }
      })();

      return {
        ...value,
        valid: false,
      };
    } else {
      return {
        ...value,
        valid: false,
      };
    }
  };

  let indication = null;
  if (!(!value.card && (!value.checkDigit || value.noCheckDigitChecked))) {
    if (loading) {
      indication = <Spin size="small" />;
    } else {
      indication = value.valid ? <CheckCircleFilled className="ant-form-item-feedback-icon-success" /> : <CloseCircleFilled className="ant-form-item-feedback-icon-error" />;
    }
  }

  useEffect(() => {
    if (value.card || value.checkDigit) {
      onChange(checkValid(value));
    }
  }, []);

  return (
    <Space direction="vertical" style={{width: '100%', verticalAlign: 'top'}}>
      <div id={id}>
        <Input.Group compact>
          <Input
            style={{width: 150}}
            value={value.card}
            maxLength={9}
            onChange={e => {
              onChange(checkValid({
                ...value,
                card: e.target.value,
              }));
            }}
            placeholder="XXXXXXXX"
          />
          <span style={{marginLeft: 4, marginRight: 4}}>(</span>
          <Input
            style={{width: 50}}
            value={value.noCheckDigitChecked ? '' : value.checkDigit}
            maxLength={1}
            onChange={e => {
              onChange(checkValid({
                ...value,
                checkDigit: e.target.value,
              }));
            }}
            disabled={value.noCheckDigitChecked}
            placeholder="X"
          />
          <span style={{marginLeft: 4, marginRight: 4}}>)</span>

          <span style={{paddingTop: 2}}>
            {indication}
          </span>
        </Input.Group>
      </div>
      <div>
        <Alert
          type="warning"
          message={(
            <Checkbox
              checked={value.noCheckDigitChecked}
              onChange={e => {
                onChange(checkValid({
                  ...value,
                  noCheckDigitChecked: e.target.checked,
                }));
              }}
            >
              {I18n.t('componentOctopusInputNoCheckDigitCheckedLabel')}
            </Checkbox>
          )}
          banner
          showIcon={false}
        />
      </div>
    </Space>
  );
};
